import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'

import { Section, Title, Text, Box, ButtonIcon } from '../../components/Core'
import PageWrapper from '../../components/PageWrapper'
import Screenshot from '../../components/Screenshot'
import NextLink from '../../components/NextLink'
import WebsiteLink from '../../components/WebsiteLink'
import Slides from '../../components/Slides'
import Contact from '../../sections/common/Contact'
import Hero from '../../sections/common/Hero'
import { Keys, Key } from '../../sections/keys/Keys'

import doctorConsulting from '../../assets/images/dc/doctor-consulting.png'
import favicon from '../../assets/images/dc/favicon.png'

import devices from '../../assets/images/dc/device-screens.png'

import dicom from '../../assets/images/dc/dicom.jpg'
import videoConsulting from '../../assets/images/dc/video-consulting.jpg'

const WorkSingle = () => {
    return (
        <>
            <PageWrapper>
                <Hero className="mt-lg-5">
                    <Container>
                        <Row>
                            <Col lg="7">
                                <Text variant="tag">FULL STACK</Text>
                                <Title mb="0.5em">Doctor Consulting</Title>
                                <Text variant="p" mb="1.6em">
                                    Doctor Consulting is cloud service developed
                                    for doctors that provided video consulting
                                    and DICOM image diagnosis of patients in a
                                    virtual setting. Prior to the covid rush to
                                    telehealth, I was already developing virtual
                                    diagnosis services for doctors in the cloud.
                                </Text>
                                <Text variant="p" mb="1.6em">
                                    Doctor Consulting was a unique challenge
                                    because of the web based DICOM imaging
                                    system that was required to properly
                                    diagnosis patients with severe spine
                                    conditions. I was tasked with coding the
                                    DICOM imaging and Video Consulting.
                                </Text>
                            </Col>
                            <Col lg="5" style={{ alignSelf: 'center' }}>
                                <img
                                    src={doctorConsulting}
                                    alt="Doctor Consulting"
                                    className="img-fluid w-100"
                                />
                            </Col>
                        </Row>
                    </Container>
                </Hero>

                <div className="mt-lg-3">
                    <Container>
                        <Row>
                            <Col lg="4" className="mb-4 mb-lg-0">
                                <Text variant="tag">Client</Text>
                                <Title variant="cardBig" className="mt-3">
                                    Doctor Consulting
                                </Title>
                            </Col>
                            <Col lg="4" className="mb-4 mb-lg-0">
                                <Text variant="tag">Time</Text>
                                <Title variant="cardBig" className="mt-3">
                                    Late 2019
                                </Title>
                            </Col>
                            <Col lg="4">
                                <Text variant="tag">Website</Text>

                                <Title variant="cardBig" className="mt-3">
                                    <WebsiteLink
                                        src={favicon}
                                        href="https://www.doctorconsulting.com"
                                    >
                                        doctorconsulting.com
                                    </WebsiteLink>
                                </Title>
                            </Col>
                        </Row>
                    </Container>
                </div>

                <Section className="mt-lg-5">
                    <Container>
                        <Row>
                            <Col lg="6" className="mb-5 pr-lg-5">
                                <img
                                    src={devices}
                                    alt="Doctor Consulting Device Screens"
                                    className="img-fluid w-100"
                                />
                            </Col>
                            <Col lg="6" className="mb-5 pl-lg-5">
                                <Title variant="cardBig" className="mb-4">
                                    Covid Rush to Virtual Office Visits
                                </Title>
                                <Text variant="p" mb="1.6em">
                                    For most people around the world, covid shut
                                    down their business. For me, I had already
                                    been developing for Doctor Consulting
                                    several months prior to shut downs and was
                                    uniquely positioned to help doctors diagnose
                                    patients in virtual settings.
                                </Text>
                                <Text variant="p" mb="1.6em">
                                    Doctor Consulting was unique from similar
                                    products in development by zocdoc and
                                    doxy.me because it was being developed for
                                    spine surgeons. Spine surgeons required a
                                    way for patients to upload and view thier
                                    medical images prior to a virtual
                                    consultation.
                                </Text>
                                <Text variant="p" mb="1.6em">
                                    Video Consultations were not going to have a
                                    mobile app available for the first phase of
                                    development so it was up to me to develop
                                    the video consultation using mobile first
                                    using Twilio Video API and WebRTC.
                                </Text>
                            </Col>
                        </Row>
                    </Container>
                </Section>
                <Keys mt="-5%">
                    <Key name="react" title="01. React " left>
                        I had previous experience using react on the Collective
                        Care project and Atlantic Spine Center website so I felt
                        confident in my ability to develop a modern user
                        interface with it. The DICOM medical image viewer was
                        built around the cornerstone js DICOM framework.
                    </Key>

                    <Key name="redux" title="02. Redux Ducks">
                        I built most of the state management for the entire
                        project using the Redux Ducks pattern. It was easier for
                        the other developers to understand and the code was
                        condensed into a single file per data model making it
                        easier for junior developers to work with.
                    </Key>

                    <Key name="twilio" title="03. Twilio" left>
                        The twilio video and sms services were used to host
                        scaleable and secure video rooms and mobile
                        notifications for doctor/patient scheduling and
                        reminders.
                    </Key>

                    <Key name="docker" title="04. Docker Swarm">
                        Each service was built seperately as docker containers
                        in Docker Swarm. A docker proxy was built to access the
                        containers across a scaleable AWS EC2 instances and a
                        load balancer controlling traffic flow.
                    </Key>
                </Keys>

                <Section mt="22%" bg="dark">
                    <Container>
                        <Row
                            css={`
                                margin-top: -35%;
                            `}
                        >
                            <Col xs="12" className="mb-5">
                                <Slides>
                                    <Screenshot
                                        src={dicom}
                                        alt="Doctor Consulting DICOM"
                                    />
                                    <Screenshot
                                        src={videoConsulting}
                                        alt="Doctor Consulting Video"
                                    />
                                </Slides>
                            </Col>
                        </Row>

                        <div className="text-center mt-lg-5">
                            <Text
                                variant="tag"
                                className="mb-1"
                                color="lightShade"
                            >
                                Next Project
                            </Text>
                            <NextLink to="/works/rig-planet/">
                                Rig Planet
                            </NextLink>
                        </div>
                    </Container>
                </Section>

                <Box py={4}>
                    <Contact />
                </Box>
            </PageWrapper>
        </>
    )
}
export default WorkSingle
